import ContactPage from "../pages/contact/ContactPage";
import NosProjets from "../pages/nos-projets/nos-projets";
import QuiSommesNous from "../pages/qui-sommes-nous/qui-sommes-nous";
import Accueil from "../pages/accueil/Accueil";
import NotreEquipe from "../pages/notre-equipe/notre-equipe";
import ConnexionPage from "../pages/connexion/ConnexionPage";
import {translate} from "../i18n/translate";
import InscriptionPage from "../pages/inscription/InscriptionPage";
import DonsPage from "../pages/dons/DonsPage";
import OubliPage from "../pages/forgotten-password/OubliPage";

// Object must have those key to get a good dynamic app need to change MockPage
export const routes = [
   {
      path: "/",
      element: <Accueil />,
      name: translate("TR_1"),
   },
   {
      path: "/qui-sommes-nous",
      element: <QuiSommesNous />,
      name: translate("TR_2"),
   },
   {
      path: "/nos-projets",
      element: <NosProjets />,
      name: translate("TR_3"),
   },
   {
      path: "/notre-equipe",
      element: <NotreEquipe />,
      name: translate("TR_4"),
   },
   {
      path: "/contact",
      element: <ContactPage />,
      name: translate("TR_5"),
   },
   {
      path: "/don",
      element: <DonsPage />,
      name: translate("TR_9"),
   },
   {
      path: "/connexion",
      element: <ConnexionPage />,
      name: translate("TR_7"),
   },
   {
      path: "/inscription",
      element: <InscriptionPage />,
      name: translate("TR_8"),
   },
   {
      path: "/forgot-password",
      element: <OubliPage />,
      name: translate("TR_180"),
   },
];
