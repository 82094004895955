import {CornerDialog, Pane, toaster} from "evergreen-ui";
import Headerpanel from "../../component/headerpanel";
import {useEffect, useState} from "react";
import DatatableODTE from "../../component/datatable";
import type {DatatableDonType} from "./../../../types/datatable-don.type";
import type {HeaderTableType} from "./../../../types/header-table.type";
import {DonService} from "./../../../services/don.service";

export const Don = () => {
   const HeaderLabel: HeaderTableType[] = [
      {key: "author", label: "Donateur", isFilter: true},
      {key: "projet", label: "Projet", isFilter: true},
      {key: "amount", label: "Montant", isFilter: false},
      {key: "moyen_paiement", label: "Moyen paiement", isFilter: true},
      {key: "date", label: "Date", isFilter: false},
      {key: "isActive", label: "statut", isFilter: false},
      {key: "action", label: "", isFilter: false},
   ];

   const configAction: any = {
      delete: {state: true, url: ""},
   };

   const title = "Donation";
   const [searchValue, setSearchValue] = useState("");
   const [dateValue, setDateValue] = useState("");
   const [listeDon, setListedon] = useState<DatatableDonType>({
      donListe: [],
      totalRow: 0,
      currentPage: 0,
   });
   const [loading, setLoading] = useState<boolean>(true);
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [tempDon, setTempdon] = useState({
      author: "",
      donateAt: "",
      hours: "",
      amount: "",
      isActive: true,
   });
   const [deleteModal, setDeleteModal] = useState<boolean>(false);

   useEffect(() => {
      getListeDon();
   }, [listeDon.currentPage]);

   const getListeDon = () => {
      setLoading(true);
      DonService.getListedon({
         pageNo: listeDon.currentPage,
         pageSize: 10,
         sortBy: "",
         sortDir: searchValue,
      })
         .then(data => {
            setListedon(prevState => ({
               ...prevState,
               donListe: data.donListe,
               currentPage: data.currentPage,
               totalRow: data.totalRow,
            }));
            setLoading(false);
         })
         .catch(() => {})
         .finally(() => {
            setLoading(false);
         });
   };

   function handleDeleteDon() {
      setDeleteModal(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const donId = tempDon.id.toString();
      if (donId !== "0") {
         void DonService.deleteDon(donId)
            .then(res => {
               if (res.success) {
                  toaster.success("OTDE NOTIFICATION", {
                     description: "Félicitation, un don a été supprimé",
                  });
                  // Rafraîchir la liste des utilisateurs après la suppression
                  getListeDon();
               }
            })
            .catch(() => {
               // Gérer les erreurs de suppression ici
               toaster.danger("OTDE NOTIFICATION", {
                  description:
                     "Une erreur s'est produite lors de la suppression du don.",
               });
            })
            .finally(() => {
               setDeleteModal(false);
            });
      }
   }

   const handleBtnSearch = () => {};

   const handleBtnExport = () => {};

   return (
      <div>
         <div className="px-4 sm:px-0"></div>
         <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
               <Headerpanel
                  title={title}
                  searchValue={searchValue}
                  dateValue={dateValue}
                  handleButtonExport={handleBtnExport}
                  handleButtonSearch={handleBtnSearch}
                  handleDateChanged={setDateValue}
                  showSeachValue={true}
                  showdatechoosed={true}
                  showexportPdf={true}
                  showSearchBtn={true}
                  setSearchValue={setSearchValue}
               />
               <Pane
                  display="flex"
                  flexDirection={"row"}
                  padding={16}
                  borderRadius={3}>
                  <DatatableODTE
                     filterValue={searchValue}
                     tableHeaderlabel={HeaderLabel}
                     tableData={listeDon.donListe}
                     totalRow={listeDon.totalRow}
                     loading={loading}
                     handleCurrenyPage={setListedon}
                     configAction={configAction}
                     handleDelete={don => {
                        setTempdon(don);
                        setDeleteModal(true);
                     }}
                  />
                  <CornerDialog
                     title="Gestion de l'application"
                     isShown={deleteModal}
                     confirmLabel={"Supprimer"}
                     cancelLabel={"Annuler "}
                     onConfirm={handleDeleteDon}
                     onCloseComplete={() => {
                        setDeleteModal(false);
                     }}>
                     Voulez-vous supprimer cette ligne ?
                  </CornerDialog>
               </Pane>
            </dl>
         </div>
      </div>
   );
};
