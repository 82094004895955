import {useState} from "react";
import ProjectModal from "./ProjectModal";
import {translate} from "../i18n/translate";

interface ProjectProps {
   srcImg: string;
   projectTitle: string;
   projectDescription: string;
   projectObjective: string;
   index: number | string;
   setSelectedProject: any;
   selectedProject: any;
}

function ProjectComponent({
   srcImg,
   projectTitle,
   projectDescription,
   projectObjective,
   index,
   setSelectedProject,
   selectedProject,
}: ProjectProps) {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const openModal = () => {
      setIsModalOpen(true);
   };
   const closeModal = () => {
      setIsModalOpen(false);
   };
   return (
      <div className="p-4 sm:w-1/2 lg:w-1/3">
         <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
            <img
               src={srcImg}
               alt="image"
               className="lg:h-72 md:h-48 w-full object-cover object-center cursor-pointer"
               onClick={openModal}
            />
            <div className=" p-6 ease-in">
               <h2 className="text-base font-medium text-red-600 mb-1 flex items-center">
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="20"
                     className="w-6 h-6 mr-3 "
                     viewBox="0 0 24 24">
                     <g transform="translate(0 24) scale(1 -1)">
                        <path
                           fill="red"
                           d="M21.909 10.928H24v2.02h-2.091zm-3.635 0h2.091v2.02h-2.091zm-6.022.001l-.657 2.215l-3.772-8.343l-1.954 6.17H0v2.02h7.346l.81-2.551l3.834 8.486l1.76-5.978h2.973v-2.02z"
                        />
                     </g>
                  </svg>
                  {projectTitle}
               </h2>
               <h1 className=" text-2xl font-semibold mb-3">
                  {projectObjective}
               </h1>
               <p className="leading-relaxed text-gray-400 mb-3">
                  {projectDescription}
               </p>
               <button
                  className="shadow bg-green-500 hover:bg-green-800 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  type="submit">
                  {translate("TR_220")}
               </button>
            </div>
         </div>
         <ProjectModal
            isOpen={isModalOpen}
            onClose={closeModal}
            modalDescription={projectDescription}
            modalTitle={projectTitle}
         />
      </div>
   );
}

export default ProjectComponent;
