import type {DonDtoType} from "../types/don-dto.type";

export const DonListeDto: DonDtoType[] = [
   {
      id: 1,
      isActive: true,
      author: "John Stripe",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Stripe",
      amount: "6500 CFA",
      date: "17-10-2023",
   },
   {
      id: 2,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
   {
      id: 3,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
   {
      id: 4,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
   {
      id: 5,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
   {
      id: 6,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
   {
      id: 7,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
   {
      id: 8,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
   {
      id: 9,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
   {
      id: 10,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },

   {
      id: 11,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
   {
      id: 12,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
   {
      id: 13,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
   {
      id: 14,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
   {
      id: 15,
      isActive: false,
      author: "John Paypal",
      projet: "Cantine à BOUAKE",
      moyen_paiement: "Paypal",
      amount: "150000 CFA",
      date: "17-10-2023",
   },
];
