import {
   ChevronRightIcon,
   DeleteIcon,
   Heading,
   Icon,
   Pane,
   Paragraph,
   Tab,
   Tablist,
   TickCircleIcon,
} from "evergreen-ui";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "./don.css";
import {type DonType} from "../../../../types/don.type";
import ContainerOTDE from "../../container/ContainerOTDE";
import {StatistiqueService} from "../../../../services/statistique.service";

export const TabDons = () => {
   const [selectedIndex, setSelectedIndex] = useState(0);
   const [donList, setDonList] = useState<DonType[]>([]);
   const [loading, setLoading] = useState<boolean>(true);

   useEffect(() => {
      getDonListe(selectedIndex);
   }, [selectedIndex]);

   const getDonListe = (idx: number) => {
      setLoading(true);
      StatistiqueService.getLastDonatedbyId(idx)
         .then(data => {
            console.log(data);
            setDonList(data);
            setLoading(false);
         })
         .catch(() => {})
         .finally(() => {
            setLoading(false);
         });
   };
   return (
      <Pane flex={1} display="flex" flexDirection={"column"}>
         <Paragraph
            flex={1}
            display="flex"
            flexDirection={"row"}
            marginBottom={10}>
            <Heading size={500} flex={1}>
               Dernier don
            </Heading>
            <Heading size={100} flex={1} color="neutral" textAlign={"right"}>
               <Link to={"/admin/don"} className={"link-don-custom"}>
                  Voir plus <Icon icon={ChevronRightIcon} size={8} />
               </Link>
            </Heading>
         </Paragraph>
         <div className="tab-container">
            <Tablist marginBottom={16} flex={1} marginRight={24}>
               <Tab
                  flex={1}
                  aria-controls={`panel-0`}
                  className={"tab-style-otde"}
                  isSelected={selectedIndex === 0}
                  onSelect={() => {
                     setSelectedIndex(0);
                  }}>
                  {" "}
                  <b>Tout</b>{" "}
               </Tab>
               <Tab
                  flex={1}
                  className={"tab-style-otde"}
                  aria-controls={`panel-1`}
                  isSelected={selectedIndex === 1}
                  onSelect={() => {
                     setSelectedIndex(1);
                  }}>
                  {" "}
                  <b>Stripe</b>{" "}
               </Tab>
               <Tab
                  flex={1}
                  className={"tab-style-otde"}
                  aria-controls={`panel-2`}
                  isSelected={selectedIndex === 2}
                  onSelect={() => {
                     setSelectedIndex(2);
                  }}>
                  {" "}
                  <b>Paypal</b>{" "}
               </Tab>
            </Tablist>
            <Pane flex={1} role="tabpanel">
               <ContainerOTDE line={5} loading={loading}>
                  {donList.map((don, index) => {
                     return (
                        <Pane
                           borderBottom={true}
                           display={"flex"}
                           flexDirection={"row"}
                           key={index}
                           height={60}
                           background={"orange"}
                           backgroundColor={"#FFF"}
                           paddingBottom={2}
                           marginBottom={2}>
                           {don.isActive && (
                              <TickCircleIcon
                                 flex={1}
                                 alignItems={"left"}
                                 color={"#1dbe00"}
                                 size={40}
                              />
                           )}
                           {!don.isActive && (
                              <DeleteIcon
                                 flex={1}
                                 alignItems={"left"}
                                 color={"#f89797"}
                                 size={40}
                              />
                           )}
                           <Pane
                              flex={2}
                              display={"flex"}
                              flexDirection={"column"}>
                              <Heading size={500} textAlign={"left"}>
                                 <b>{don.author}</b>
                              </Heading>
                              <Paragraph textAlign={"left"} color="neutral">
                                 {don.donateAt}
                              </Paragraph>
                           </Pane>
                           <Pane
                              flex={2}
                              display={"flex"}
                              flexDirection={"column"}>
                              <Heading size={500} textAlign={"right"}>
                                 <b>+ {don.amount}</b>
                              </Heading>
                              <Paragraph textAlign={"right"} color="neutral">
                                 {don.hours}
                              </Paragraph>
                           </Pane>
                        </Pane>
                     );
                  })}
               </ContainerOTDE>
            </Pane>
         </div>
      </Pane>
   );
};
