import type {DonType} from "../types/don.type";

export const DonMockStripe: DonType[] = [
   {
      isActive: true,
      author: "John Stripe",
      amount: "6500 CFA",
      donateAt: "17-10-2023",
      hours: "9h45 PM",
   },
   {
      isActive: false,
      author: "John Stripe",
      amount: "6500 CFA",
      donateAt: "17-10-2023",
      hours: "9h45 PM",
   },
];

export const DonMockPaypal: DonType[] = [
   {
      isActive: true,
      author: "John paypal",
      amount: "6500 CFA",
      donateAt: "17-10-2023",
      hours: "9h45 PM",
   },
   {
      isActive: false,
      author: "John paypal",
      amount: "6500 CFA",
      donateAt: "17-10-2023",
      hours: "9h45 PM",
   },
];

export const DonMockAll: DonType[] = [
   {
      isActive: true,
      author: "John paypal",
      amount: "6500 CFA",
      donateAt: "17-10-2023",
      hours: "9h45 PM",
   },
   {
      isActive: false,
      author: "John paypal",
      amount: "6500 CFA",
      donateAt: "17-10-2023",
      hours: "9h45 PM",
   },
   {
      isActive: true,
      author: "John Stripe",
      amount: "6500 CFA",
      donateAt: "17-10-2023",
      hours: "9h45 PM",
   },
   {
      isActive: false,
      author: "John Stripe",
      amount: "6500 CFA",
      donateAt: "17-10-2023",
      hours: "9h45 PM",
   },
];
